@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
  .input-field {
    @apply text-neutral-medium-blue focus:outline-none focus:border-none active:border-none border-[1px] border-[solid] border-[#ccc] rounded-[0.25rem] box-border font-bold w-full p-[5px] font-[14px] min-h-[28px];
  }
  .input-field-error {
    @apply !border-red-700 !border-[1px] !border-solid;
  }
}

body {
    margin: 0;
    /* font-family: "IBM Plex Sans", sans-serif; */
}

.highlight {
    background-color: yellow;
    cursor: pointer;
}
/* Custom Scrollbar styles for elements with the 'Details' class */
::-webkit-scrollbar {
    width: 0px; 
}

::-webkit-scrollbar-track {
    background: #121212; 
}

::-webkit-scrollbar-thumb {
    background: #6A55FE; 
}
.swiper-button-prev:after, .swiper-button-next:after{
    font-size: 15px !important;
    color: white;
}

.swiper-button-prev:disabled::after, .swiper-button-next:disabled::after{
    color: surface-tertiary-white;
}
.fzvXhU {
    background-color: transparent !important; /* Make background transparent */
    gap: 8px; /* Add gap between elements */
}
.iYTiyq {
    background-color: transparent !important;
}

.swiper-button-prev:after, .swiper-button-next:after{
    font-size: 15px !important;
    color: white;
}

.swiper-button-prev:disabled::after, .swiper-button-next:disabled::after{
    color: surface-tertiary-white;
}

/* Add this CSS to your global stylesheet or inline styles */

/* Chat container styles */
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Messages area */
.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
}

/* User message */
.user-message {
  text-align: right;
  margin-bottom: 8px;
}

.user-message div {
  display: inline-block;
  background-color: #2b6cb0;
  color: white;
  padding: 8px 12px;
  border-radius: 12px;
}

/* Bot message */
.bot-message {
  text-align: left;
  margin-bottom: 8px;
}

.bot-message div {
  display: inline-block;
  background-color: #e2e8f0;
  color: #2d3748;
  padding: 8px 12px;
  border-radius: 12px;
}

/* Input form */
.chat-input-form {
  display: flex;
  padding: 16px;
  border-top: 1px solid #e2e8f0;
}

.chat-input {
  flex: 1;
  border: 1px solid #cbd5e0;
  border-radius: 24px;
  padding: 8px 16px;
  margin-right: 8px;
}

.chat-submit-button {
  background-color: #2b6cb0;
  color: white;
  padding: 8px 16px;
  border-radius: 24px;
}

.chat-submit-button:hover {
  background-color: #2c5282;
}
